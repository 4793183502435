import React, { useEffect } from 'react';
import { InlineWidget } from 'react-calendly';

import Header from '../components/Header';
import Footer from '../components/Footer';

import styles from '../styles/booking-demo.module.css';

function bookingDemo() {
  function isCalendlyEvent(e) {
    return e.data.event && e.data.event.indexOf('calendly') === 0;
  }

  useEffect(() => {
    window.addEventListener('message', function (e) {
      if (isCalendlyEvent(e)) {
        if (e.data.event === 'calendly.event_type_viewed') {
          typeof window !== 'undefined' &&
            window.gtag('event', 'conversion', {
              send_to: 'AW-827182913/hMucCNfd6fABEMGet4oD'
            });
        }
        if (e.data.event === 'calendly.date_and_time_selected') {
          typeof window !== 'undefined' &&
            window.gtag('event', 'conversion', {
              send_to: 'AW-827182913/nb4ZCLuM8fABEMGet4oD'
            });
        }
        if (e.data.event === 'calendly.event_scheduled') {
          typeof window !== 'undefined' &&
            window.gtag('event', 'conversion', {
              send_to: 'AW-827182913/9H0nCLLZ6fABEMGet4oD'
            });
        }
      }
    });
  }, []);

  return (
    <>
      <Header hideCta />
      <div className={styles.Introduction}>
        <h1 className={styles.Message}>
          Book your personalized demo of Hire Hero
        </h1>
      </div>
      <div className={styles.CalendlyCtn}>
        <InlineWidget
          url="https://calendly.com/acceleratorapp-team/hire-hero"
          pageSettings={{
            hideEventTypeDetails: true,
            hideLandingPageDetails: true
          }}
        />
      </div>
      <Footer hideCta />
    </>
  );
}

export default bookingDemo;
